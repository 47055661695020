// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars             as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown        as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft        as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight       as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion   as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser       as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faCircleXmark      as fasFaCircleXmark     } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock            as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment          as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder           as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass  as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag              as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faPhone            as fasFaPhone           } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faEnvelope         as fasFaEnvelope        } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faCreditCard       as fasFaCreditCard      } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faArrowRight       as fasFaArrowRight      } from "@fortawesome/pro-solid-svg-icons/faArrowRight";

import { faUser             as fasFaUser            } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faAngleRight       as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";

/**
 * Add solid icons
 */
library.add(
    fasFaBars,
    fasFaCaretDown,
    fasFaCaretLeft,
    fasFaCaretRight,
    fasFaCircleQuestion,
    fasFaCircleUser,
    fasFaClock,
    fasFaCircleXmark,
    fasFaComment,
    fasFaFolder,
    fasFaMagnifyingGlass,
    fasFaTag,
    fasFaPhone,
    fasFaEnvelope,
    fasFaCreditCard,
    fasFaArrowRight,
    fasFaAngleRight,
    fasFaUser
);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
